<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ currentTable }}</h4>
          <div class="row">
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select class="select-primary pagination-select" v-model="pagination.perPage" placeholder="Por página">
                <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
              </el-select>

              <div class="d-flex align-items-center">
                <el-button class="btn new-btn mr-3" @click="$router.push({path: '/sugestao-materiais'})">Cadastrar Nova</el-button>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Procurar item" v-model="searchQuery" aria-controls="datatables"></el-input>
              </div>
            </div>
            <div class="col-12">
              <el-table :data="queriedData.filter(data => !searchQuery || data.name.toLowerCase().includes(searchQuery.toLowerCase()))" v-if="total > 0">
                <el-table-column :min-width="20" v-if="total > 0" align="left" label="Imagem">
                  <div slot-scope="props" class="photo material-cover">
                    <a :href="`${props.row.photo_url}`" target="_blank">
                      <img :src="props.row.photo_url" alt="" />
                    </a>
                  </div>
                </el-table-column>

                <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label"></el-table-column>

                <el-table-column v-if="total > 0" align="right" label="Coletor">
                  <template v-slot="props">
                    <router-link :to="`usuario/${props.row.user_id}`">{{props.row.user_name}}</router-link>
                  </template>
                </el-table-column>

                <el-table-column min-width="108" v-if="total > 0" align="right" label="Ações">
                  <template v-slot="props">
                    <el-button type="success" icon="tim-icons icon-check-2" plain circle @click="handleApprove(props.$index, props.row)" size="small"></el-button>
                    <el-button type="danger" icon="tim-icons icon-simple-remove" plain circle @click="handleReprove(props.$index,props.row)" size="small"></el-button>
                    <el-button type="danger" icon="tim-icons icon-trash-simple" plain circle @click  ="handleDelete(props.$index, props.row)" size="small"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category table-footer" v-if="total > 0">
                Exibindo de {{ from + 1 }} à {{ to }} de {{ total }} registros
              </p>
              <p class="card-category table-footer" v-if="total === 0">
                Nenhum registro encontrado.
              </p>
            </div>
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total" v-if="total > 0"></base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Button } from 'element-ui'
import { BasePagination } from '@/components'
import Fuse from 'fuse.js'
import swal from 'sweetalert2'
export default {
  data () {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0
      },
      searchQuery: '',
      propsToSearch: [],
      tableColumns: [],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    }
  },
  methods: {
    edit (index, row) {
      return this.$router.push(`/sugestoes-materiais/${row.user_id}`)
    },
    handleDelete (index, row) {
      swal.fire({
        title: 'Você tem certeza?',
        text: `Você está prestes a deletar "${row.name}".`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Sim, eu quero deletar!',
        cancelButtonText: 'Cancelar',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          swal.fire({
            title: 'Deletando',
            text: `Aguarde, estamos deletando "${row.name}".`,
            type: 'info',
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.deleteRow(row).then(status => {
            if (status) {
              swal.fire({
                title: 'Deletado!',
                text: `Você deletou "${row.name}"`,
                type: 'success',
                showConfirmButton: false,
                timer: 2000
              })
            } else {
              swal.fire({
                title: 'Error!',
                text: `Ocorreu um erro ao deletar "${row.name}"`,
                type: 'error',
                confirmButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'Ok',
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    async deleteRow (row) {
      let status
      try {
        status = (await this.$http.delete(`item-suggestions/${row.id}`)).status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToDelete = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToDelete >= 0) {
          // this.tableData[indexToDelete].status = "Inativo";
          this.tableData.splice(indexToDelete, 1)
        }
        return true
      }
      return false
    },
    handleReprove (index, row) {
      swal
        .fire({
          title: 'Você tem certeza?',
          text: `Você está prestes a reprovar a sugestão "${row.name}".`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Sim, eu quero reprovar',
          cancelButtonText: 'Cancelar',
          buttonsStyling: false
        })
        .then(result => {
          if (result.value) {
            swal.fire({
              title: 'Reprovando',
              text: `Aguarde, estamos reprovando "${row.name}".`,
              type: 'info',
              onBeforeOpen: () => {
                swal.showLoading()
              }
            })
            this.reprove(row).then(status => {
              if (status) {
                this.getData()
                swal.fire({
                  title: 'Reprovando!',
                  text: `Você reprovou "${row.name}"`,
                  type: 'success',
                  showConfirmButton: false,
                  timer: 2000
                })
              } else {
                swal.fire({
                  title: 'Error!',
                  text: `Ocorreu um erro ao reprovar "${row.name}"`,
                  type: 'error',
                  confirmButtonClass: 'btn btn-danger btn-fill',
                  confirmButtonText: 'Ok',
                  showConfirmButton: true
                })
              }
            })
          }
        })
    },
    handleApprove (index, row) {
      swal
        .fire({
          title: 'Você tem certeza?',
          text: `Você está prestes a aprovar a sugestão "${row.name}".`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Sim, eu quero aprovar',
          cancelButtonText: 'Cancelar',
          buttonsStyling: false
        })
        .then(result => {
          if (result.value) {
            swal.fire({
              title: 'Aprovando',
              text: `Aguarde, estamos aprovando "${row.name}".`,
              type: 'info',
              onBeforeOpen: () => {
                swal.showLoading()
              }
            })
            this.approve(row).then(status => {
              if (status) {
                this.getData()
                swal.fire({
                  title: 'Aprovando!',
                  text: `Você aprovou "${row.name}"`,
                  type: 'success',
                  showConfirmButton: false,
                  timer: 2000
                })
              } else {
                swal.fire({
                  title: 'Error!',
                  text: `Ocorreu um erro ao aprovar "${row.name}"`,
                  type: 'error',
                  confirmButtonClass: 'btn btn-danger btn-fill',
                  confirmButtonText: 'Ok',
                  showConfirmButton: true
                })
              }
            })
          }
        })
    },
    async approve (row) {
      let status
      try {
        status = (await this.$http.post(`item-suggestions/approve-status/${row.id}`)).status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToDelete = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToDelete >= 0) {
          // this.tableData[indexToDelete].status = "Inativo";
          this.tableData.splice(indexToDelete, 1)
        }
        return true
      }
      return false
    },
    async reprove (row) {
      let status
      try {
        status = (await this.$http.post(`item-suggestions/reprove-status/${row.id}`)).status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToDelete = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToDelete >= 0) {
          // this.tableData[indexToDelete].status = "Inativo";
          this.tableData.splice(indexToDelete, 1)
        }
        return true
      }
      return false
    },
    async getData () {
      const url = 'item-suggestions'
      const { data } = await this.$http.get(url)
      this.tableData = data.data.data
      this.tableColumns = [
        {
          prop: 'id',
          label: 'Id',
          minWidth: 40
        },
        {
          prop: 'name',
          label: 'Material Sugerido',
          minWidth: 200
        },

        {
          prop: 'status_name',
          label: 'Status',
          minWidth: 40
        }
      ]

      this.propsToSearch = ['name', 'unity_id']
    }
  },
  mounted () {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.3
    })
    this.getData()
  },
  watch: {
    searchQuery (value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    }
  },
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button
  },
  computed: {
    queriedData () {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length
    },
    currentTable () {
      const msg = `lista de ${this.$route.name}`
      return msg.toUpperCase()
    }
  }
}
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

p.table-footer {
  text-transform: none;
}

.btn-new {
  height: 2.5rem;
}
</style>
